import React from 'react'
import Layout from '~/layouts/layout'
import SEO from '~/components/seo'
import Img from 'gatsby-image'
import { Link, graphql } from 'gatsby'

const IndexPage = ({ data }) => (
  <Layout language="en" en="" nl="">
    <SEO title="Valta" keywords={[`lamp`, `furniture`, `design`]} />
    <div className="gridFullWidth heroContainer boxShadow">
      <Img
        className="heroImage"
        data-sal="fade"
        data-sal-duration="800"
        fluid={data.allFile.nodes[6].childImageSharp.fluid}
      ></Img>
      <div className="heroText">
        <h1>
          A star is born:
          <br />
          Valta Aster
        </h1>
        <div
          style={{
            marginTop: `60px`,
            minWidth: `62%`,
            display: `flex`,
            flexWrap: `wrap`,
          }}
        >
          <Link to="/products/" className="buttonOutline">
            All products
          </Link>
          <Link to="/products/valta-aster" className="buttonWhite">
            Check lamp
          </Link>
        </div>
      </div>
    </div>
    <div className="gridFullWidth heroContainer boxShadow">
      <Img
        className="heroImage"
        data-sal="fade"
        data-sal-duration="800"
        fluid={data.allFile.nodes[8].childImageSharp.fluid}
      ></Img>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    allFile(
      filter: {
        relativePath: {
          in: [
            "frontpage-hero.jpg"
            "valta-atmosphere-1.jpg"
            "valta-atmosphere-2.jpg"
            "valta-atmosphere-3.jpg"
            "valta-atmosphere-4.jpg"
            "valta-aster-1.jpg"
            "valta-aster-2.jpg"
            "valta-aster-3.jpg"
            "valta-aster-4.jpg"
            "valta-aster-5.jpg"
          ]
        }
      }
    ) {
      nodes {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

export default IndexPage
